<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container">
      <div
        v-if="additionalDescriptionData.photoUrl"
        class="photo-frame-wrapper"
      >
        <img
          :src="apiUrl + additionalDescriptionData.photoUrl.replace(/\\/g, '/')"
          alt="Additional Description Photo"
          class="photo-frame is-preview"
          @click="openLightbox()"
        />
      </div>
      <h2 class="big-text" v-if="eventDate">{{ eventDate }}</h2>
      <div class="generic-panel big-text">{{ additionalDescriptionData.title }}</div>
      <p class="description">{{ additionalDescriptionData.description }}</p>

      <VueEasyLightbox
        :visible="visible"
        :imgs="[currentImg]"
        :index="0"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: 'AdditionalDescriptionPage',
  components: {VueEasyLightbox},
  props: {
    additionalDescriptionData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImg: '',
    };
  },
  computed: {
    eventDate() {
      const { year, month, day } = this.additionalDescriptionData;

      if (!day && !month && !year) {
        return '';
      }

      if (day && month && !year) {
        return `${day}.${month}`;
      }

      if (year && !month) {
        return year.toString();
      }

      return `${day}.${month}.${year}`;
    },
  },
  methods: {
    openLightbox() {
      this.currentImg = this.apiUrl + this.additionalDescriptionData.photoUrl;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 48px; // Large font size for the date
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px; // Provide space between the title and description
}

// Reuse the .small-text style from profile.scss for the description and panel text
</style>
