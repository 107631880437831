<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container">
      <div class="header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-book-circle-32x32.svg">
        </v-icon>
        <div class="title small-text">Edukacja</div>
      </div>
      <div v-if="educationData.photoUrl" class="photo-frame-wrapper">
        <img
          :src="apiUrl + (typeof educationData.photoUrl === 'string' ? educationData.photoUrl.replace(/\\/g, '/') : '')"
          alt="Education Photo"
          class="photo-frame is-preview"
          @click="openLightbox()"
        />
      </div>
      <h2 class="big-text">{{ educationData.startYear }} - {{ educationData.endYear }}</h2>
      <div class="big-text">{{ educationData.place }}</div>
      <p class="description">{{ educationData.description }}</p>

      <VueEasyLightbox
        :visible="visible"
        :imgs="[currentImg]"
        :index="0"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: 'EducationPage',
  components: {VueEasyLightbox},
  props: {
    educationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImg: '',
    };
  },
  methods: {
    openLightbox() {
      this.currentImg = this.apiUrl + this.educationData.photoUrl;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-page-container {
  background-image: url('@/assets/images/profile_education_background.png');
  padding-top: 8px; /* Reduced padding to decrease space at the top */
}

.header .icon {
  font-size: 24px;
}

.title {
  margin-top: 8px;
  // Inherits small-text styles from profile.scss
}

.location-arrow .icon {
  font-size: 24px; // Adjust size as needed
  display: block; // Ensure the icon is centered
  margin: 16px auto; // Center the icon horizontally and add some margin
}

/* Additional styles can be added as needed to match the design */
</style>
